export function getDocumentFormat(file: File): string {
  // Only the subtypes are acceptable values according to Larousse
  // https://github.com/getbreathelife/larousse/blob/master/properties/documentFormat.yaml
  const match = file.type.match(/\w+\/(\w+)/);

  if (match && match.length > 1) {
    return match[1];
  }

  return '';
}
