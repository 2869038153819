import { group } from './typewriter/group';
import { identify } from './typewriter/identify';
import { page } from './typewriter/page';
import { trackingMethods } from './typewriter/track';

export const TypewriterTracking = {
  ...trackingMethods,
  identify,
  page,
  group,
};

export * from './types';
export * from './helpers';
