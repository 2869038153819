import { Language } from '@breathelife/types';

type PageLoaded = {
  hashedId: string | null;
  language: Language;
};

export function page(props: PageLoaded): void {
  const analytics: SegmentAnalytics.AnalyticsJS | undefined = window.analytics;

  if (!analytics) {
    return;
  }

  if (!Object.values(Language).includes(props.language)) {
    // eslint-disable-next-line no-console
    console.error(`Invalid language value used for analytics.page: ${props.language}`);
  }

  analytics.page(props, {
    context: {
      ip: null,
    },
  });
}
