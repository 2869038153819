import { IdentifyProperties } from '../types';

export function identify(props: IdentifyProperties, callback?: () => void): void {
  const analytics: SegmentAnalytics.AnalyticsJS | undefined = window.analytics;

  if (!analytics) {
    callback?.();
    return;
  }

  analytics.identify(
    props.userId,
    props.traits,
    {
      context: {
        ip: null,
      },
    },
    callback,
  );
}
