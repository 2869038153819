export type IdentifyProperties = {
  userId: string;
  traits: {
    userType: string;
    locale: string;
  };
  context?: Record<string, unknown>;
};

export enum ButtonName {
  callForAssistance = 'callForAssistance',
  switchedLanguage = 'switchedLanguage',
  moreInformation = 'moreInformation',
  callerIdentification = 'callerIdentification',
  sharePublicLinkCopyToClipBoard = 'sharePublicLinkCopyToClipBoard',
  externalResources = 'externalResources',
  userProfileUploadPicture = 'updateProfileUploadPicture',
  leadNewProfileCancel = 'leadNewProfileCancel',
  leadEditProfileCancel = 'leadEditProfileCancel',
  leadNewProfileSave = 'leadNewProfileSave',
  leadEditProfileSave = 'leadEditProfileSave',
  leadContactedToggle = 'leadContactedToggle',
  leadNoteSave = 'leadNoteSave',
  generateLink = 'generateLink',
  userEditCopyPublicAgentLink = 'userEditCopyPublicAgentLink',
  savedAdvisorProfile = 'savedAdvisorProfile',
}

export enum ScreenName {
  login = 'login',
  logout = 'logout',
  leadsListActive = 'leadsListActive',
  leadsListArchived = 'leadsListArchived',
  leadNeedsAnalysisEmail = 'leadNeedsAnalysisEmail',
  leadProfile = 'leadProfile',
  leadDelete = 'leadDelete',
  notificationPreferences = 'notificationPreferences',
  scheduleACallForm = 'scheduleACallForm',
  sharePublicLink = 'sharePublicLink',
  userProfile = 'userProfile',
}

export enum FieldId {
  userProfileFirstName = 'userProfileFirstName',
  userProfileLastName = 'userProfileLastName',
  userProfilePreferredName = 'userProfilePreferredName',
  userProfileEmail = 'userProfileEmail',
  userProfilePhoneNumber = 'userProfilePhoneNumber',
}

export enum ModuleName {
  needsAnalysis = 'needsAnalysis',
  application = 'application',
}
